<template>
  <div class="user">
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="download">导出记录</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
    <template #labelNames>
        <el-table-column label="标签" width="390" align="center">
          <template #default="scoped">
            <div v-if="scoped.row.labelNames"><el-tag style="margin: 0 5px 5px 0;" v-for="(item,i) in scoped.row.labelNames.split(',')" type="warning" :key="i">{{ item }}</el-tag></div>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="380" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleChangeList(scoped.row.id)"
            >
              积分记录
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="integralChange(scoped.row)"
            >
              记录积分变更
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleUnblock(scoped.row)"
              v-if="scoped.row.banState == 1"
            >
              解封
            </el-button>
            <el-button
              v-if="scoped.row.banState == 0"
              type="primary"
              @click="block(scoped.row)"
              size="small"
            >
              封号
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleChangeSet(scoped.row)"
            >
              {{ scoped.row.type == 0 ? '设置工作人员' : '设置顾客' }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="remarkVisible"
      title="记录积分变更"
      width="50%"
      class="user_dialog"
      :before-close="remarkClose"
    >
      <!-- <div class="writeoff_box">
        <span>活动类型：</span>
        <el-select v-model="type" class="dialog_input" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="writeoff_box">
        <span>变更积分类型：</span>
        <el-select v-model="valType" class="dialog_input" placeholder="请选择">
          <el-option
            v-for="item in valTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="writeoff_box">
        <span>变更积分：</span>
        <el-input
          v-model="jfVal"
          type="text"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <div class="writeoff_box remark_box">
        <span>备注：</span>
        <el-input
          v-model="remarkVal"
          :rows="5"
          type="textarea"
          placeholder="请输入"
          class="dialog_input"
        ></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="remarkSubmit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="listVisible"
      title="积分记录"
      width="50%"
      :before-close="listClose"
      class="user_dialog"
    >
      <TableView
        :loading="listLoading"
        :columns="listColumns"
        :data="listrTableData"
        :pagination="listPagination"
        :is-pagination-show="false"
        @getData="getDataList"
      >
      </TableView>
      <div class="block">
        <el-pagination
          :current-page="listPagination.current"
          :page-size="listPagination.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listPagination.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="listClose"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  freeze,
  unblock,
  guestPage,
  userExport,
  dictActive,
  dictClassify,
  operationIntegral,
  integralHistory,
  userSetAdmin
} from '@/api/userManagement/user'
const initData = reactive({
  formLabels: [
    {
      label: 'keywords',
      title: '关键字',
      placeholder: '请输入微信昵称或手机号'
    },
    {
      title: '账号状态',
      label: 'status',
      type: 'options',
      options: [
        { value: 1, label: '封号' },
        { value: 0, label: '正常' }
      ],
      placeholder: '请选择'
    },
    {
      title: '用户类型',
      label: 'type',
      type: 'options',
      options: [
        { value: 1, label: '工作人员' },
        { value: 0, label: '顾客' }
      ],
      placeholder: '请选择'
    }
  ],
  fromData: {
    // 表单数据
    keywords: '',
    status: '',
    member: '',
    type: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'nickName', label: '微信昵称' },
    { prop: 'phone', label: '手机号' },
    { prop: 'typeName', label: '用户类型' },
    { prop: 'currentActiveIntegral', label: '当前活动积分' },
    { prop: 'totalActiveIntegral', label: '累计活动积分' },
    { prop: 'currentShoppingIntegral', label: '当前购物积分' },
    { prop: 'totalShoppingIntegral', label: '累计购物积分' },
    { prop: 'lastLoginTime', label: '登录时间' },
    { prop: 'stateName', label: '状态' },
    { slot: 'labelNames' },
    { prop: 'channelName', label: '渠道' },
    { slot: 'action' }
  ],
  remarkVisible: false,
  jfVal: '',
  remarkVal: '',
  type: '',
  valType: '',
  typeList: [],
  valTypeList: [],
  userId: '',
  listVisible: false,
  listrTableData: [],
  listColumns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'classifyName', label: '变更积分类型' },
    { prop: 'remark', label: '备注' },
    { prop: 'integral', label: '积分' },
    { prop: 'createTime', label: '记录时间' },
    { slot: 'action' }
  ],
  listLoading: false,
  listPagination: {
    current: 1,
    size: 10,
    total: 0
  }
})

onMounted(() => {
  getDataList()
  getDict()
})

// 活动类型、变更积分类型
const getDict = () => {
  dictActive().then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      typeList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
  dictClassify().then(({ data: res }) => {
    if (res.code == 200) {
      const list = res.data.map(item => {
        return {
          label: item.value,
          value: item.code
        }
      })
      valTypeList.value = list
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keywords: '',
    status: '',
    type: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keywords !== '') {
    data.word = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.banState = fromData.value.status
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  guestPage(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      res.data.records.forEach(item => {
        item.stateName = item.banState == 1 ? '封号' : '正常'
        item.newUserName = item.newUser ? '是' : '否'
      })
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 解封
const handleUnblock = row => {
  ElMessageBox.confirm('解封后该会员将恢复参与活动，是否确认？', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      unblock({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 用户导出
const download = () => {
  const data = {}
  if (fromData.value.keywords !== '') {
    data.word = fromData.value.keywords
  }
  if (fromData.value.status !== '') {
    data.banState = fromData.value.status
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  userExport(data).then(res => {
    let url = window.URL.createObjectURL(new Blob([res.data]))
    let a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.setAttribute('download', `微信用户数据记录-${dateTime()}.xls`)
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
  })
}

const dateTime = () => {
  const dateday = new Date()
  let year = dateday.getFullYear()
  let getMonth = dateday.getMonth() + 1
  let getDay = dateday.getDate()
  if (getMonth < 10) {
    getMonth
  }
  return `${year}-${getMonth < 10 ? '0' + getMonth : getMonth}-${
    getDay < 10 ? '0' + getDay : getDay
  }`
}

// 积分变更
const integralChange = row => {
  jfVal.value = ''
  remarkVal.value = ''
  type.value = ''
  valType.value = ''
  userId.value = row.id
  remarkVisible.value = true
}

// 积分变更弹窗关闭
const remarkClose = () => {
  jfVal.value = ''
  remarkVal.value = ''
  type.value = ''
  valType.value = ''
  remarkVisible.value = false
}

// 提交
const remarkSubmit = () => {
  // if (type.value == '') {
  //   ElMessage.warning('请选择活动类型')
  //   return false
  // }
  if (valType.value == '') {
    ElMessage.warning('请选择变更积分类型')
    return false
  }
  if (jfVal.value == '') {
    ElMessage.warning('请输入变更积分')
    return false
  }
  if (remarkVal.value == '') {
    ElMessage.warning('请输入备注')
    return false
  }
  const data = {
    userId: userId.value,
    classify: valType.value,
    // refCode: type.value,
    integral: jfVal.value,
    remark: remarkVal.value
  }
  operationIntegral(data).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      remarkClose()
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 积分记录
const handleChangeList = id => {
  userId.value = id
  listVisible.value = true
  listLoading.value = true
  const data = {
    current: listPagination.value.current,
    size: listPagination.value.size,
    userId: userId.value
  }
  integralHistory(data).then(({ data: res }) => {
    if (res.code == 200) {
      listLoading.value = false
      listrTableData.value = res.data.records
      listPagination.value.total = Number(res.data.pageInfo.total)
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 切换页码
const handleCurrentChange = val => {
  listPagination.value.current = val
  handleChangeList(userId.value)
}

// 切换页条
const handleSizeChange = val => {
  listPagination.value.size = val
  handleChangeList(userId.value)
}

const listClose = () => {
  listVisible.value = false
  listPagination.value.current = 1
  listPagination.value.size = 10
}
// 封号
const block = row => {
  ElMessageBox.confirm('封号后该会员将无法参与活动，是否确认?', '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      freeze({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 设置工作人员
const handleChangeSet = row => {
  ElMessageBox.confirm(
    `是否确认设置为${row.type === 0 ? '工作人员' : '顾客'}？`,
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      userSetAdmin({
        userId: row.id,
        type: row.type === 1 ? 0 : 1
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  remarkVisible,
  jfVal,
  remarkVal,
  type,
  valType,
  typeList,
  valTypeList,
  userId,
  listVisible,
  listrTableData,
  listColumns,
  listLoading,
  listPagination
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.user_dialog {
  .writeoff_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    span {
      width: 100px;
      text-align: right;
    }
    .dialog_input {
      width: 50%;
    }
  }
  .remark_box {
    align-items: flex-start;
  }
  .block {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
}
</style>
